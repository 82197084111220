<header class="app_header">
    <div class="header_flex">
        <div class="header_left">
            <button class="btn btn_link btn-lg btn-floating mob_nav_trigger">
                <i class="ri-menu-2-line"></i>
            </button>

            <a (click)="onClickMysubmission()" class="btn btn_link btn-lg btn-floating header_back_btn">
                <i class="ri-home-line"></i>
            </a>
            <div class="brand_icon">
                <img src="../../../../assets/img/logo.png">
            </div>
        </div>
        <div class="header_right">
            <div class="mob_nav_overlay">
                <button class="btn btn_link btn-lg btn-floating mob_nav_close">
                    <i class="ri-close-line"></i>
                </button>
            </div>
            <!-- <div class="header_nav">
                <ul>
                    <li><a href="javascript:void(0)" class="link_btn" (click)="openGuideLineModal()">View Guidelines</a></li>
					<li><a href="javascript:void(0)" class="link_btn">Download Templates</a></li>
					<li><a href="javascript:void(0)" class="link_btn">Help Desk</a></li>
                    <li class="mob_show"><a href="javascript:void(0)" class="link_btn">About IASGCON 2024</a></li>
                </ul>
            </div> -->
            
            
			<div ngbDropdown class="d-inline-block">
                <button type="button" class="btn btn_link header_user dropdown-toggle dropdown-toggle-split"
                    id="dropdownBasic1" ngbDropdownToggle>
                    <div class="user_circle">
                        <i class="ri-user-line" aria-hidden="true"></i>
                        <!-- <img src="../../../assets/img/avatar.png"> -->
                    </div>
                    <div class="user_body">
                        <span class="name">Dr. {{userName}}</span>
                        <span class="email">{{loginedUserEmail}}</span>
                    </div>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <!-- <button ngbDropdownItem><a class="dropdown-item unlink">
                            {{userName}}<br>
                            {{loginedUserId}}
                        </a></button> -->
                    <button ngbDropdownItem><a class="dropdown-item" (click)="logoutConfirm()">Logout</a></button>
                </div>
            </div>
        </div>
    </div>
</header>

<div class="wrap_fluid">
    
    <div class="wrap_content">
        <div class="wrap_container">
			<form novalidate [formGroup]="formGroup">                
				<div>
				  <div class="card card-default">
					<div class="card-header">
					  <h4 class="card-title">
						<i class="fa fa-user-secret"></i>  Change Password
					  </h4>
					</div>
					<div class="card-body">
					  <span class="form-text text-muted"> You will need to login again after changing the password.</span>
					  <br />
					  <div class="form-group">
						<label class="col-form-label" for="UserName"> User I.D/Email</label>
						<div>
						  <span class="form-control  ">{{loginedUserEmail}}</span>
						  <input  type="hidden" name="UserName" formControlName="userName" />
						</div>
					  </div>
					  <div class="form-group">
						<label class="col-form-label" for="OldPassword"> Old Password</label>
						<div>
						  <input pInputText maxlength="50" class="form-control" formControlName="oldPassword" id="OldPassword" name="OldPassword"
							type="password" />
						</div>
						<div class="forms_error_info" *ngIf="formGroup.controls['oldPassword'].invalid && formGroup.controls['oldPassword'].dirty">
						  <i class="fa fa-close mr-1"></i>
						  <span *ngIf="formGroup.controls['oldPassword'].errors.required">Old Password is required!</span>
						  <span *ngIf="formGroup.controls['oldPassword'].errors.minlength">  Old Password must be 8 characters or more</span>
						  <span *ngIf="formGroup.controls['oldPassword'].errors.maxlength"> Old Password is too long</span>
						</div>
					  </div>
					  <div class="form-group">
						<label class="col-form-label" for="Password"> Password</label>
						<div>
						  <input pInputText maxlength="50" class="form-control" 
							id="Password" formControlName="password"   name="Password" type="password"/>
						</div>
						<div class="forms_error_info" *ngIf="formGroup.controls['password'].invalid && formGroup.controls['password'].dirty">
						  <i class="fa fa-close mr-1"></i>
						  <span *ngIf="formGroup.controls['password'].errors.required">Password is required</span>
						  <span *ngIf="formGroup.controls['password'].errors.minlength">Password must be 8 characters or more</span>
						  <span *ngIf="formGroup.controls['password'].errors.maxlength">Password is too long</span>
						</div>
					  </div>
					  <div class="form-group">
						<label class="col-form-label" for="ConfirmPassword"> Confirm Password</label>
						<div>
						  <input pInputText maxlength="50" class="form-control"
						   formControlName="confirmPassword" id="ConfirmPassword" name="ConfirmPassword" type="password" />
						</div>
						<div class="forms_error_info" *ngIf="formGroup.controls['confirmPassword'].invalid && formGroup.controls['confirmPassword'].dirty">
						  <i class="fa fa-close mr-1"></i>
						  <span *ngIf="formGroup.controls['confirmPassword'].errors.required">Password is required</span>
						  <span *ngIf="formGroup.controls['confirmPassword'].errors.minlength">Password must be 8 characters or more</span>
						  <span *ngIf="formGroup.controls['confirmPassword'].errors.maxlength">Password is too long</span>
						  <span *ngIf="formGroup.controls['confirmPassword'].errors.PasswordMisMatch && !formGroup.controls['confirmPassword'].errors.required &&  !formGroup.controls['confirmPassword'].errors.minlength && !formGroup.controls['confirmPassword'].errors.maxlength">Passwords don't match</span>               
						</div>
					  </div>
					</div>
					<div class="card-footer">
						<div class="row">
							<div class="col text-right">
								<button class="btn btn-outline-primary mr-2" (click)="onClickMysubmission()"> Cancel</button>
								<button class="btn btn-primary" (click)="onSubmit(formGroup.value)"> Submit</button>
							</div>
						</div>
						
					</div>
				  </div>
				</div>
			  </form>
        </div>
    </div>

    <!-- App Footer -->
    <footer class="app_footer">
        <div class="footer_flex">
			<p>© IADVL ACADEMY - All rights reserved.</p>
            <!-- <ul>
                <li><a href="https://www.isshp2023.com/privacy-policy.html">Privacy Policy</a></li>
                <li><a href="javascript:void(0)">Terms &amp; Conditions</a></li>
            </ul> -->
        </div>
    </footer>
    <!--/. App Footer -->
</div>

<!-- Guidline Modal -->
<div class="modal fade" bsModal #guidlineModal="bs-modal" id="guidlineModal" tabindex="-1"
	aria-labelledby="guidlineModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-lg modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="guidlineModalLabel">Abstract Guidelines</h5>
				<button type="button" class="btn-close" (click)="guidlineModal.hide()" data-mdb-dismiss="modal"
					aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<div class="static">
					<p style="line-height:1.2; background-color:#ffffff"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">We kindly ask you to read these guidelines before submitting your abstract.&nbsp;</span></span></span></span></span></span></p>
                        
					<ul>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">You must be a registered delegate to present at the conference if your abstract is accepted for presentation.&nbsp;</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">Please note the registration fee details and the deadlines as given in the brochure. It is in your best interest to do an early bird registration to avail a discounted fee.</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">Registration for the conference is not mandatory for the submission of an abstract.</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">If you have any queries, please contact the Scientific Secretariat at&nbsp;</span></span></span></span></span></span><a href="mailto:iadvl2024@gmail.com" style="text-decoration:none"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#0000ff"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:underline"><span style="-webkit-text-decoration-skip:none"><span style="text-decoration-skip-ink:none">iadvl2024@gmail.com</span></span></span></span></span></span></span></span></a><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">&nbsp;</span></span></span></span></span></span></li>
					</ul>
					
					<p style="line-height:1.2; background-color:#ffffff"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:700"><span style="font-style:normal"><span style="text-decoration:none">Guidelines for abstract submission</span></span></span></span></span></span></p>
					
					<ul>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">Abstracts should be submitted on line. Abstracts submitted by email or any other mode will not be accepted</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">An abstract can be submitted to IASGCON 2024 only if it has not been published previously or has not been presented in more than one national meeting.&nbsp;</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">The authors must choose a topic from the themes mentioned below.</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">The authors must disclose their preferred mode of presentation during the online submission, i.e., oral presentation or electronic poster presentation.</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">Grouping of data from the same work is highly recommended. Splitting a study into two abstracts is prohibited and will be rejected.</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">The scientific method and result(s) or outcome(s) should be mentioned clearly in the abstract.</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">Abstracts must contain preliminary data and have appropriate Institutional Ethical Committee clearance or meet International Ethical Standards.&nbsp; The authors need to upload the Institutional Ethical Committee approval certificate at the time of abstract submission.</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">Conflict of interests: Authors and co-authors must declare any financial or any other interests of any company in connection with the study.</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">All submitted abstracts will be peer-reviewed anonymously. It will be scored, accepted, and allocated by merit to the appropriate category of presentation.&nbsp;</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">All final decisions regarding the theme and category will be made by the Scientific Communications Committee.</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">Only one communication per presenting author is allowed.</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">If the presenting author does not submit before the below mentioned submission dates to IASGCON 2024 to validate his/her communication, their chance to present will be forfeited.</span></span></span></span></span></span></li>
					</ul>
					
					<p style="line-height:1.2; margin-left:24px; text-indent:18pt; background-color:#ffffff"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:700"><span style="font-style:normal"><span style="text-decoration:none">Submission Dates:</span></span></span></span></span></span></p>
					
					<p style="line-height:1.2; margin-left:24px; text-indent:18pt; background-color:#ffffff"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">Last date without late fees - August 15th, 2024</span></span></span></span></span></span></p>
					
					<p style="line-height:1.2; margin-left:24px; text-indent:18pt; background-color:#ffffff"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">Last date with late fees&nbsp; -&nbsp; August 31st, 2024</span></span></span></span></span></span></p>
					
					<ul>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">Under exceptional circumstances, if the presenting author is unable to present at the conference, they will be permitted to nominate one of the listed co-authors as the presenting author, who must be a registered delegate. The same should be communicated to the Secretary, IASG and Organising Secretary, IASGCON and Chairman, IASG, Scientific Committee</span></span></span></span></span></span></li>
					</ul>
					<ul>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">Video abstracts (text) should be submitted along with uploading the Video file
							- Video file should be high quality and duration of up to 8 minutes (only), in the usually readable compressed video formats (MP4, MPEG4)</span></span></span></span></span></span></li>
					</ul>
					
					<p style="line-height:1.2; background-color:#ffffff"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:700"><span style="font-style:normal"><span style="text-decoration:none">IASGCON Publication Policy</span></span></span></span></span></span></p>
					
					<ul>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">Submitting an abstract allows IASGCON to record and replay your presentation (if accepted and presented).</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">All abstracts validated for presentation will also be published as an e-abstract book. By submitting your abstract, you must agree for the abstract to be published. Please note that you are responsible for proofing your abstracts.&nbsp;</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">All abstract titles and authors will be listed in the scientific programme.&nbsp;</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">The presenting authors will be sent detailed guidelines including the timing of their presentation once their abstract is accepted. If you need to withdraw your abstract, a written statement reflecting the reasons for your withdrawal must be sent to </span></span></span></span></span></span><a href="mailto:iadvl2024@gmail.com" style="text-decoration:none"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#0000ff"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:underline"><span style="-webkit-text-decoration-skip:none"><span style="text-decoration-skip-ink:none">iadvl2024@gmail.com</span></span></span></span></span></span></span></span></a><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none"> no later than </span></span></span></span></span></span><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:700"><span style="font-style:normal"><span style="text-decoration:none">August 31st, 2024.</span></span></span></span></span></span></li>
					</ul>
					
					<p style="line-height:1.2; background-color:#ffffff"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:700"><span style="font-style:normal"><span style="text-decoration:none">Tracks for Abstract submission</span></span></span></span></span></span></p>
					
					<ol>
						<li aria-level="1" style="list-style-type:decimal"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">Esophagus</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:decimal"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">Stomach</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:decimal"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">Liver</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:decimal"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">Biliary</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:decimal"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">Pancreas</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:decimal"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">Small Bowel</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:decimal"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">Colo-rectal</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:decimal"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">Transplantation</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:decimal"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">Miscellaneous</span></span></span></span></span></span></li>
					</ol>
					
					<p style="line-height:1.2; background-color:#ffffff"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:700"><span style="font-style:normal"><span style="text-decoration:none">Submission Categories</span></span></span></span></span></span></p>
					
					<ul>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">Free Paper</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">Video Abstract</span></span></span></span></span></span></li>
					</ul>
					
					<p style="line-height:1.2; background-color:#ffffff"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:700"><span style="font-style:normal"><span style="text-decoration:none">Abstract Structure</span></span></span></span></span></span></p>
					
					<ul>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">Abstracts must be submitted in English.</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">The abstract must not contain more than 35 lines (blank spaces &amp; punctuation included).</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">The title of the abstract must be limited to 300 characters.&nbsp;</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">The size of the abstract must be limited to 300 words.&nbsp;</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">No tables or figures should be included in the abstract.</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">Abstracts should be structured using the following subheadings:</span></span></span></span></span></span>
						<ul>
							<li aria-level="2" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">For original research work: Objectives, Methods, Results, Conclusions.&nbsp;</span></span></span></span></span></span></li>
							<li aria-level="2" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">For case reports: no subheadings</span></span></span></span></span></span></li>
						</ul>
						</li>
					</ul>
					
					<ul>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">Abbreviations may be used but only after prior definition.</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">Abstracts should have the expected scientific merit.</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">Trade names and company names must not be mentioned in the title. However, trade names in brackets will be accepted in the body of the text if they are necessary to differentiate from similar products or to specify certain delivery devices or application modes.</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">Authors must submit a blinded abstract. The authors&rsquo; names and affiliations should not be included/mentioned in the abstract.</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">Please enter the details of all authors in the submission form in order of appearance in the abstract.</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">If you have not completed all the required sections and details, your abstract will not be considered for review and will be rejected.</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">If you have difficulty or questions regarding the submission process, please contact the office via email at </span></span></span></span></span></span><a href="mailto:iadvl2024@gmail.com" style="text-decoration:none"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#0000ff"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:underline"><span style="-webkit-text-decoration-skip:none"><span style="text-decoration-skip-ink:none">iadvl2024@gmail.com</span></span></span></span></span></span></span></span></a><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#0000ff"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:underline"><span style="-webkit-text-decoration-skip:none"><span style="text-decoration-skip-ink:none">&nbsp;</span></span></span></span></span></span></span></span></li>
					</ul>
					
					<ul>
						<li aria-level="2" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">You can change the order of authors by rearranging the numbers.</span></span></span></span></span></span></li>
						<li aria-level="2" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">Conflict of interests: Authors and co-authors must declare any financial or any other interests of any company in connection with the study.</span></span></span></span></span></span></li>
						<li aria-level="2" style="list-style-type:disc"><span style="font-size:12pt; font-variant:normal; white-space:pre-wrap"><span style="font-family:Calibri,sans-serif"><span style="color:#222222"><span style="font-weight:400"><span style="font-style:normal"><span style="text-decoration:none">Once the author submits the abstract, all the co-authors will be notified by email.</span></span></span></span></span></span></li>
					</ul>
					
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn app_btn btn_close" (click)="guidlineModal.hide()"
					data-mdb-dismiss="modal">Close</button>
			</div>
		</div>
	</div>
</div>
<!--/. Guidline Modal -->